export const branches = [
  {
    key: 'lombard1',
    lat: 38.524316,
    lng: 68.76278,
    tels: ['93-999-00-61', '90-999-00-61'],
    hours: `Открыто:
Пн-Сб: 8-17
Вс: 9-14`,
    address: 'г.Душанбе, ул. Н.Карабаева 92/2',
  },
  {
    key: 'lombard2',
    lat: 38.554818,
    lng: 68.762541,
    tels: ['93-300-09-99', '90-993-09-99'],
    hours: `Открыто:
Пн-Сб: 8-17
Вс: 9-14`,
    address: 'г.Душанбе, ул. Н.Карабаева 6',
  },
]
